import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getErrorMessage } from '@core/common-constants';
import {
  UserRole,
  AllRecordsRequest,
  ApproveUserRecord,
  CatalogCode,
  ControlPageFacade,
  EffectiveRole,
  Entitlement,
  EntitylementTypes,
  ErrorContext,
  ICatalogInfo,
  IDomainMessage,
  IDomainUpdateResponse,
  IEulaConfig,
  Invitation,
  ITenantDetail,
  IUpdatedPreferences,
  LedgerEntry,
  LedgerRequest,
  LogoUrl,
  NoContentApiResponse,
  PaginatedTableResponse,
  ServiceVisibility,
  SignalRFacade,
  SnackBarFacade,
  TenantService,
  TenantUtilityTokens,
  UserFeedback,
  UserPreferences,
  Version,
  TenantUtilityTokensResponse,
  SortFilterContext,
  Case,
  TrialReservation,
  ReservationInfo,
  FavoriteApps,
  defaultLanguage,
  PageRequest,
  PageResponse,
  TenantUser,
  Trial,
  TrialBundle,
} from '@ra-state';
import { ConfigService, CoreConfiguration } from '@rockwell-automation-inc/service';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { combineLatest, EMPTY, expand, Observable, of, reduce, throwError, TimeoutError } from 'rxjs';
import { catchError, filter, map, skipWhile, switchMap, take, tap, timeout } from 'rxjs/operators';
import { CommandRequest } from './command-request.service';
import { LoggerService } from './logger.service';
import { getEnvOrDefault } from '@app/utils';
import { ENV_VARS } from '@app/models/config.model';
import { tryParseAppId } from '@rockwell-automation-inc/common-utils';
import { checkIfTrialEntitlement } from '@app/v2/shared/utils';

class ErrorContextImpl implements ErrorContext {
  url: string;
  message: string;
  correlationId: string | undefined;
  constructor(private readonly httpError: HttpErrorResponse) {
    this.url = this.httpError.url ?? '';
    this.message = `${this.httpError.message}`;
    this.correlationId = String(httpError.headers.get('RequestId') ?? 'Not Available');
  }
}

interface ICatalogMetadata {
  productNumber: string;
  name: string;
  description: string;
}

type ApiVersion = {
  application: string;
  version: string;
  processName: string;
  productVersion: string;
};
@Injectable()
export class DataService {
  private baseUrl: string;
  private portalUrl: string;
  private userBaseUrl: string;
  private tenantBaseUrl: string;
  private suggestedTenantsUrl: string;
  private eulaConfigBaseUrl: string;
  private entitlementConfigBaseUrl: string;
  private notificationBaseUrl: string;
  private suggestedTenantByCodeUrl: string;
  private invitationsUrl: string;
  private approveUsers: string;
  private trialBaseUrl: string;
  private trialBundlesUrl: string;
  user: UserPreferences;
  catalogUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService<CoreConfiguration>,
    private logger: LoggerService,
    private controlPageFacade: ControlPageFacade,
    private snackBarFacade: SnackBarFacade,
    private router: Router,
    private signalRFacade: SignalRFacade,
  ) {
    this.logger = logger.withContext('DataService');
  }

  // Call this on control page effects when auth0 is resolved
  initializeEndpoints(): void {
    this.baseUrl = this.configService.config.csApiBaseUrl;
    this.portalUrl = this.configService.config.csPortalUrl;
    this.userBaseUrl = `${this.baseUrl}${ApiEndPoints.Users}`;
    this.tenantBaseUrl = `${this.baseUrl}${ApiEndPoints.Tenant}`;
    this.eulaConfigBaseUrl = `${this.baseUrl}${ApiEndPoints.Eula}`;
    this.entitlementConfigBaseUrl = `${this.baseUrl}${ApiEndPoints.Entitlement}`;
    this.catalogUrl = `${this.baseUrl}${ApiEndPoints.Catalog}`;
    this.suggestedTenantsUrl = `${this.baseUrl}${ApiEndPoints.SuggestedTenants}`;
    this.suggestedTenantByCodeUrl = `${this.baseUrl}${ApiEndPoints.SuggestedTenantByCode}`;
    this.notificationBaseUrl = this.configService.config.notificationsBaseUrl;
    this.invitationsUrl = `${this.baseUrl}${ApiEndPoints.Invitations}`;
    this.approveUsers = `${this.baseUrl}${ApiEndPoints.AccessRequest}`;
    this.trialBaseUrl = `${this.baseUrl}${ApiEndPoints.Trial}`;
    this.trialBundlesUrl = `${this.baseUrl}${ApiEndPoints.TrialBundles}`;
  }

  getUser$(userId: string): Observable<UserPreferences> {
    const url = `${this.userBaseUrl}${userId}/preferences`;
    return this.http.get<UserPreferences>(url).pipe(
      map((user) => {
        const favoriteApps = user.preferences?.favoriteApps
          ? (JSON.parse(user.preferences?.favoriteApps as any) as FavoriteApps)
          : {};
        if (user.preferences) {
          user.preferences.favoriteApps = favoriteApps;
          user.preferences.language = user.preferences?.language ?? defaultLanguage;
        }

        return user;
      }),
      catchError((error: unknown) => this.ignoreNotFoundError$(error)),
    );
  }

  setAllowDuringMaintenanceHeader(headers?: HttpHeaders): HttpHeaders {
    const hdrs = headers || new HttpHeaders();
    return hdrs.set('allow_during_maintenance', 'true');
  }

  updateUserPreferences$(userId: string, updatedPreferences: IUpdatedPreferences): Observable<NoContentApiResponse> {
    const url = `${this.userBaseUrl}${userId}/preferences`;
    return this.http
      .put<NoContentApiResponse>(url, updatedPreferences, {
        headers: this.setAllowDuringMaintenanceHeader(),
        observe: 'response',
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  updateUserProfile$(): Observable<NoContentApiResponse> {
    const url = `${this.userBaseUrl}info`;
    return this.http
      .post<NoContentApiResponse>(url, undefined)
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getEulaConfig$(): Observable<IEulaConfig> {
    const url = this.eulaConfigBaseUrl;
    return this.http.get<IEulaConfig>(url).pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getCatalogInfo$(): Observable<ICatalogInfo[]> {
    return this.http.get<ICatalogMetadata[]>(this.catalogUrl).pipe(
      map((catalogResp) =>
        catalogResp.map((catalog) => {
          return {
            catalogCode: catalog.productNumber,
            description: catalog.description,
            name: catalog.name,
          } as ICatalogInfo;
        }),
      ),
      catchError((error: unknown) => this.handleError$(error)),
    );
  }

  getSuggestedTenants$(
    pageRequest: PageRequest,
    sortFilterContext: SortFilterContext,
  ): Observable<PageResponse<ITenantDetail>> {
    const url = `${this.suggestedTenantsUrl}?perPage=${pageRequest.perPage}&pageNum=${pageRequest.pageNum}`;
    const sortFilterModel = sortFilterContext ?? {};
    return this.http
      .get<PageResponse<ITenantDetail>>(url, {
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getSuggestedTenantByCode$(inviteCode: string): Observable<ITenantDetail[]> {
    const url = `${this.suggestedTenantByCodeUrl}${inviteCode}`;
    return this.http.get<ITenantDetail[]>(url).pipe(catchError((error: unknown) => this.ignoreNotFoundError$(error)));
  }

  setTenantIdHeader(headers: HttpHeaders, tenantId: string): HttpHeaders {
    return headers.set('tenantid', tenantId);
  }

  getTenantEffectiveRoles$(tenantId: string, userId: string): Observable<EffectiveRole[]> {
    const url = `${this.userBaseUrl}${userId}/tenanteffectiveroles`;
    return this.http
      .get<EffectiveRole[]>(url, { headers: this.setTenantIdHeader(new HttpHeaders(), tenantId) })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getTenantResourceRoles$(
    pageRequest: PageRequest,
    sortFilterContext: SortFilterContext,
  ): Observable<PageResponse<UserRole>> {
    const url = `${this.tenantBaseUrl}resourceroles?perPage=${pageRequest.perPage}&pageNum=${pageRequest.pageNum}`;
    const sortFilterModel = sortFilterContext ?? {};

    return this.http
      .get<PageResponse<UserRole>>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), String(pageRequest.tenantId)),
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getUserResources$(tenantId: string): Observable<any> {
    const url = `${this.tenantBaseUrl}resources`;
    const headers = new HttpHeaders().set('tenantId', tenantId);
    return this.http.get<any>(url, { headers }).pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getAllTenantResourceRoles$(tenantId: string, userId?: string): Observable<UserRole[]> {
    const url = `${this.tenantBaseUrl}resourceroles`;
    let httpParams = new HttpParams();
    if (userId) {
      httpParams = httpParams.append('user', userId);
    }
    const headers = this.setTenantIdHeader(new HttpHeaders(), tenantId);
    return this.getAllRecords$<UserRole>({
      url: url,
      httpParams: httpParams,
      headers: headers,
    });
  }

  getTenantUsers$(payload: PageRequest): Observable<PageResponse<TenantUser>> {
    let url = `${this.baseUrl}${ApiEndPoints.Tenant}users`;
    if (payload?.perPage) {
      url = `${this.baseUrl}${ApiEndPoints.Tenant}users?perPage=${payload?.perPage}&pageNum=${payload?.pageNum}`;
    }

    const sortFilterModel = payload.sortFilterContext ? payload.sortFilterContext : {};
    return this.http
      .get<PageResponse<any>>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), String(payload.tenantId)),
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getAllRecords$<T>(request: AllRecordsRequest): Observable<T[]> {
    const startPageNum = 1;
    let fetchedCount = 0;
    const perPage = request.perPage ?? 10;
    let httpParams = request.httpParams
      ? request.httpParams.append('perPage', perPage).append('pageNum', startPageNum)
      : new HttpParams().set('perPage', perPage).set('pageNum', startPageNum);
    const headers = request.headers;

    return this.http.get<PageResponse<T>>(request.url, { headers: request.headers, params: httpParams }).pipe(
      expand((response) => {
        fetchedCount = fetchedCount + response.records.length;
        httpParams = httpParams.set('pageNum', response.currentPage + 1);
        return fetchedCount === response.total
          ? EMPTY
          : this.http.get<PageResponse<T>>(request.url, {
              headers: headers,
              params: httpParams,
            });
      }),
      map((res) => res.records),
      reduce((acc: T[], current: T[]) => acc.concat(current), []),
    );
  }

  getFakeCatalogCodes$(): Observable<Array<CatalogCode>> {
    const url = `${this.entitlementConfigBaseUrl}mock`;
    return this.http.get<Array<CatalogCode>>(url);
  }

  shareFeedback$(userFeedback: UserFeedback): Observable<NoContentApiResponse> {
    const url = `${this.baseUrl}${ApiEndPoints.Feedback}`;
    return this.http
      .post<NoContentApiResponse>(url, userFeedback, { observe: 'response' })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getEntitlements$(
    userId: string,
    pageRequest: PageRequest,
    sortFilterContext: SortFilterContext,
  ): Observable<PageResponse<Entitlement>> {
    const url = `${this.userBaseUrl}${userId}/entitlements?perPage=${pageRequest.perPage}&pageNum=${pageRequest.pageNum}`;
    const sortFilterModel = sortFilterContext ?? {};
    return this.http
      .get<PageResponse<Entitlement>>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), String(pageRequest.tenantId)),
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(
        map((data) => {
          data.records.forEach((entitlement) => {
            //TODO: remove this piece of code when the backend provides the correct combineType
            if (_.isEmpty(entitlement.attributes?.combineType) && entitlement.serviceKind === '') {
              entitlement.attributes = {
                ...entitlement.attributes,
                combineType: EntitylementTypes.utilityToken,
              };
            }
            entitlement.isTrialEntitlement = checkIfTrialEntitlement(entitlement);
          });

          return data;
        }),
        catchError((error: unknown) => this.handleError$(error)),
      );
  }

  getUnallocatedTrials$(
    userId: string,
    pageRequest: PageRequest,
    sortFilterContext: SortFilterContext,
  ): Observable<PageResponse<Trial>> {
    const url = `${this.userBaseUrl}${userId}/trials?perPage=${pageRequest.perPage}&pageNum=${pageRequest.pageNum}`;
    const sortFilterModel = sortFilterContext ?? {};
    return this.http
      .get<PageResponse<Trial>>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), String(pageRequest.tenantId)),
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  allocateTrial$(userId: string, trialId: string, tenantId: string): Observable<NoContentApiResponse> {
    const url = `${this.userBaseUrl}${userId}/trials/${trialId}/tenantallocate`;
    return this.http
      .put<NoContentApiResponse>(url, null, {
        headers: this.setTenantIdHeader(new HttpHeaders(), String(tenantId)),
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getAllocatedTrials$(pageRequest: PageRequest, sortFilterContext: SortFilterContext): Observable<PageResponse<Trial>> {
    const url = `${this.tenantBaseUrl}trials?perPage=${pageRequest.perPage}&pageNum=${pageRequest.pageNum}`;
    const sortFilterModel = sortFilterContext ?? {};
    return this.http
      .get<PageResponse<Trial>>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), String(pageRequest.tenantId)),
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getInvitations$(
    pageRequest: PageRequest,
    sortFilterContext: SortFilterContext,
  ): Observable<PageResponse<Invitation>> {
    const url = `${this.invitationsUrl}?perPage=${pageRequest.perPage}&pageNum=${pageRequest.pageNum}`;
    const sortFilterModel = sortFilterContext ?? {};
    return this.http
      .get<PageResponse<Invitation>>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), String(pageRequest.tenantId)),
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getUploadLogoUrl$(tenantId: string): Observable<LogoUrl> {
    const url = `${this.baseUrl}/api/Tenant/logoUploadUri`;
    return this.http
      .get<LogoUrl>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), tenantId),
      })
      .pipe(catchError(this.handleError$.bind(this)));
  }

  uploadOrgLogo$(url: string, blobImage: Blob): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-ms-blob-type', 'BlockBlob');
    headers = headers.set('Content-Type', 'image/png image/jpeg');

    return this.http
      .put(url, blobImage, { headers })
      .pipe(catchError((error: unknown) => this.handleTenantLogoUploadError$(error)));
  }

  getContent$(url: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('Cache-Control', 'no-cache');
    return this.http.get(url, { responseType: 'text', headers }).pipe(
      catchError((error: unknown) => {
        this.logger.error('error: ', error);
        return of(null) as Observable<never>;
      }),
    );
  }

  getVersions$(): Observable<Version> {
    const UNKNOWN_VERSION = 'Unknown/Local';
    const UNKNOWN_VERSION$ = (err: unknown): Observable<string> => {
      this.logger.warn('error', err);
      return of(UNKNOWN_VERSION);
    };
    const apiVersionUrl = `${this.baseUrl}${ApiEndPoints.Version}`;
    const notificationServiceVersion = `${this.notificationBaseUrl}/version`;
    return combineLatest([
      this.http.get<ApiVersion>(apiVersionUrl).pipe(
        map((v) => v.productVersion),
        catchError(UNKNOWN_VERSION$),
      ),
      this.http.get<ApiVersion>(notificationServiceVersion).pipe(
        map((v) => v.productVersion),
        catchError(UNKNOWN_VERSION$),
      ),
      of(getEnvOrDefault(ENV_VARS.VERSION, UNKNOWN_VERSION)),
    ]).pipe(
      map(([apiVersion, notificationsVersion, portalVersion]) => {
        return {
          apiVersion: apiVersion,
          portalVersion: portalVersion,
          notificationsVersion: notificationsVersion,
        } as Version;
      }),
    );
  }

  getApproveUserList$(pageRequest: PageRequest): Observable<PageResponse<ApproveUserRecord>> {
    const url = `${this.approveUsers}?perPage=${pageRequest.perPage}&pageNum=${pageRequest.pageNum}`;
    return this.http
      .get<PageResponse<ApproveUserRecord>>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), String(pageRequest.tenantId)),
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  approveUser$(userId: string): Observable<NoContentApiResponse> {
    const url = `${this.approveUsers}${userId}/approve`;
    return this.http
      .post<NoContentApiResponse>(url, userId, { observe: 'response' })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getServicesVisibility$(tenantId: string): Observable<ServiceVisibility[]> {
    const url = `${this.tenantBaseUrl}services/visibility`;
    return this.http
      .get<ServiceVisibility[]>(url, { headers: this.setTenantIdHeader(new HttpHeaders(), tenantId) })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getTenantServices$(userId: string, tenantId: string): Observable<TenantService[]> {
    const url = `${this.userBaseUrl}${userId}/tenantservices`;
    const headers = new HttpHeaders().set('tenantId', tenantId);
    return this.http
      .get<TenantService[]>(url, { headers })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getTenantUtilityTokens$(tenantId: string): Observable<TenantUtilityTokens> {
    const url = `${this.tenantBaseUrl}utilitytokens`;
    return this.http
      .get<TenantUtilityTokensResponse>(url, { headers: this.setTenantIdHeader(new HttpHeaders(), tenantId) })
      .pipe(
        map((utilityToken) => {
          const tokenEnts = utilityToken.tokenEntitlements.map((ent) => ({
            ...ent,
            id: ent.entitlementId,
            isTrialEntitlement: checkIfTrialEntitlement(ent),
          }));
          const disabledTokenEnts = utilityToken.disabledTokenEntitlements.map((ent) => ({
            ...ent,
            id: ent.entitlementId,
            isTrialEntitlement: checkIfTrialEntitlement(ent),
          }));
          return {
            id: utilityToken.id,
            tokenBalance: utilityToken.tokenBalance,
            tokenExpiration: utilityToken.tokenExpiration,
            tokenEntitlements: tokenEnts,
            disabledTokenEntitlements: disabledTokenEnts,
          };
        }),
        catchError((error: unknown) => this.handleError$(error)),
      );
  }

  getTenantLedger$(ledgerRequest: LedgerRequest): Observable<PaginatedTableResponse<LedgerEntry>> {
    let params = new HttpParams()
      .set('from', ledgerRequest.from.toISOString())
      .set('duration', ledgerRequest.duration)
      .set('export', ledgerRequest.forExport);

    if (ledgerRequest.perPage) {
      params = params.set('perPage', ledgerRequest.perPage);
    }
    if (ledgerRequest.token) {
      params = params.set('token', ledgerRequest.token);
    }

    const url = `${this.tenantBaseUrl}utilitytokens/ledger`;
    return this.http
      .get<PaginatedTableResponse<LedgerEntry>>(url, {
        headers: this.setTenantIdHeader(new HttpHeaders(), ledgerRequest.tenantId),
        params: params,
      })
      .pipe(
        map((ledgerEntries) => {
          ledgerEntries.records.forEach((record) => {
            record.Item.timestamp = record.Item.ts;
            if (record.Case === Case.Debit) {
              const debitRec = record.Item;
              debitRec.serviceKind = tryParseAppId(debitRec.serviceKind) ?? debitRec.serviceKind;
            }
          });
          return ledgerEntries;
        }),
      );
  }

  getTrialBundles$(): Observable<TrialBundle[]> {
    const url = `${this.trialBundlesUrl}`;
    return this.http.get<TrialBundle[]>(url).pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getTrialReservationInfo$(request: ReservationInfo): Observable<TrialReservation> {
    const url = `${this.trialBaseUrl}/${request.campaignId}/reservations/${request.trialReservationId}`;
    return this.http.get<TrialReservation>(url).pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  getUsersActiveEntitlements$(userId: string): Observable<PageResponse<Entitlement>> {
    const url = `${this.userBaseUrl}${userId}/entitlements?perPage=1&pageNum=1`;
    const sortFilterModel = { filterModel: { status: { filterType: 'set', values: ['Active'] } } };
    return this.http
      .get<PageResponse<Entitlement>>(url, {
        params: {
          sortFilterOpts: JSON.stringify(sortFilterModel),
        },
      })
      .pipe(catchError((error: unknown) => this.handleError$(error)));
  }

  commandRequest$(commandRequest: CommandRequest): Observable<IDomainUpdateResponse> {
    this.controlPageFacade.setLoading(true);
    return this.http
      .request(commandRequest.Method, commandRequest.ApiEndpoint, {
        body: commandRequest.RequestOptions.body,
        headers: commandRequest.RequestOptions.headers,
        observe: commandRequest.RequestOptions.observe,
      })
      .pipe(
        switchMap((response) => this.toDomainResponse$(response, commandRequest)),
        tap(() => this.controlPageFacade.setLoading(false)),
        catchError((error: unknown) => {
          this.controlPageFacade.setLoading(false);
          return this.handleApiError$(error, commandRequest);
        }),
      );
  }

  private handleApiError$(error, commandRequest: CommandRequest): Observable<any> {
    if (commandRequest.errorHandler$) {
      return commandRequest.errorHandler$(error);
    }
    const httpError = error as HttpErrorResponse;
    const customErrorMessage = commandRequest.getCustomErrorMsg;
    switch (httpError.status) {
      case 400: {
        const errorCode = httpError.error.errorCode;
        const displayError = {
          message: getErrorMessage(errorCode, customErrorMessage) || 'The request was invalid',
          type: 'Error',
        };
        this.snackBarFacade.displayMessage(displayError);
        return throwError(() => error);
      }
      case 403: {
        const message = getErrorMessage(403, customErrorMessage);
        this.snackBarFacade.displayMessage({
          message: message,
          type: 'Error',
        });
        return throwError(() => error);
      }
      case 429: {
        const retryAfterSeconds = DateTime.fromISO(httpError.error.retryAfter).second;
        let message = `You can retry again after ${retryAfterSeconds} seconds.`;
        const type = 'Error';
        const errorCode = httpError.error.errorCode;
        const errorMsg = getErrorMessage(errorCode);
        if (errorMsg) {
          message = `${errorMsg} ${message}`;
        }
        this.snackBarFacade.displayMessage({
          message: message,
          type: type,
        });
        return EMPTY;
      }
      default: {
        this.controlPageFacade.navigateToError(new ErrorContextImpl(httpError));
        return throwError(() => error);
      }
    }
  }

  private toDomainResponse$(
    response: HttpResponse<Object>,
    commandRequest: CommandRequest,
  ): Observable<IDomainUpdateResponse> {
    if (commandRequest.skipWaitingOnResponse(response.status)) {
      return of({ response } as IDomainUpdateResponse);
    }
    return this.signalRFacade.domainMessage$.pipe(map((domainMessage) => domainMessage as IDomainMessage)).pipe(
      filter((domainMessage) => {
        return domainMessage?.context.correlationId === response.headers.get('RequestId');
      }),
      skipWhile((domainMessage: IDomainMessage) => {
        this.logger.log('recd domain message', domainMessage);
        return commandRequest.notEventPredicateSatisfied(domainMessage);
      }),
      take(1),
      map((domainMessage) => {
        const domainUpdateResponse: IDomainUpdateResponse = {
          message: domainMessage,
          response: response,
        };
        return domainUpdateResponse;
      }),
      timeout(commandRequest.TimeOutInMillies),
      catchError((error: unknown) => {
        if (error instanceof TimeoutError) {
          this.snackBarFacade.displayMessage({
            message: 'Operation Timeout. Please refresh.',
            type: 'Error',
          });
          return throwError(() => new Error(`Timeout for ${commandRequest}`));
        }
        return throwError(() => error);
      }),
    );
  }

  private ignoreNotFoundError$(error: unknown): Observable<never> {
    const httpError = error as HttpErrorResponse;
    if (httpError.status === 404) {
      this.logger.warn('not found: ', error);
      return throwError(() => error);
    }
    return this.handleError$(error);
  }

  private handleError$(error: unknown): Observable<never> {
    this.logger.error('error: ', error);
    const httpError = error as HttpErrorResponse;

    // client errors
    if (httpError.status === 400) {
      const message = 'Bad Request: The request was invalid';
      this.snackBarFacade.displayMessage({
        message: message,
        type: 'Error',
      });
      return throwError(() => error);
    }

    if (httpError.status === 403) {
      let message = 'Insufficient Permissions. Redirecting to dashboard';
      if (this.router.url === '/eula' || this.router.url === '/dashboard') {
        message = 'Insufficient Permissions';
      }
      this.snackBarFacade.displayMessage({
        message: message,
        type: 'Error',
      });
      this.router.navigate(['/dashboard']);
      return throwError(() => error);
    }

    this.controlPageFacade.navigateToError(new ErrorContextImpl(httpError));
    return throwError(() => error);
  }

  private handleTenantLogoUploadError$(error: unknown): Observable<never> {
    this.logger.error('tenant logo : ', 'Tenant logo size exceeds maximum allowed limit');
    this.snackBarFacade.displayMessage({
      message: 'The tenant logo file is too large. File should be less than 125kB',
      type: 'Error',
    });
    return throwError(() => error);
  }
}

export const ApiEndPoints = {
  Version: '/api/version',
  AccessRequest: '/api/accessrequests',
  Catalog: '/api/Catalog',
  Eula: '/api/eula/',
  Entitlement: '/api/entitlement/',
  Feedback: '/api/feedbacks',
  Invitations: '/api/invitations/',
  Notification: '/api/messages/',
  NotificationCounts: '/api/messages/count',
  SuggestedTenantByCode: '/api/tenants/invitecode/',
  SuggestedTenants: '/api/tenants/suggested2/',
  Tenant: '/api/tenant/',
  Tenants: '/api/tenants/',
  Users: '/api/users/',
  Trial: '/api/trialcampaigns',
  TrialBundles: '/api/trialbundles',
};
