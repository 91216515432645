import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { AppState, IDomainMessage } from '../lemans-app.model';
import { disconnect, setDomainMessageReceived } from './signalr.actions';
import { ensureIsConnectedSelector$, isConnected$, isDisonnected$, selectDomainMessage } from './signalr.selectors';
@Injectable({
  providedIn: 'root',
})
export class SignalRFacade {
  domainMessage$ = this.store$.select(selectDomainMessage).pipe(filter((domainMessage) => domainMessage !== undefined));
  isHubConnected$ = isConnected$(this.store$);
  hubDisconnected$ = isDisonnected$(this.store$);
  ensureIsConnected$ = this.ensureHubIsConnected$();
  constructor(private store$: Store<AppState>) {}

  private ensureHubIsConnected$(maxWait?: number): Observable<any> {
    return ensureIsConnectedSelector$(this.store$, maxWait ?? 20000);
  }

  disconnect(): void {
    this.store$.dispatch(disconnect());
  }

  setDomainMessageReceived(domainMessage: IDomainMessage): void {
    this.store$.dispatch(setDomainMessageReceived({ payload: domainMessage }));
  }
}
