import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { WelcomeDialogComponent } from './welcome-dialog/welcome-dialog.component';
import { Observable } from 'rxjs';
import { ModalComponent } from './modal.component';
import { CatalogDialogComponent } from '@core/modal/catalog-dialog/catalog-dialog/catalog-dialog.component';

export interface ConfirmModalConfig extends ConfirmModalContent {
  onConfirm?: () => void;
}

export interface ConfirmModalContent {
  header: string;
  body: string;
  cancelButtonText?: string;
  OKButtonText?: string;
  options?: MatDialogConfig;
}

export interface AlertModalConfig extends AlertModalContent {
  onConfirm?: () => void;
}

export interface AlertModalContent {
  header: string;
  body: string;
  OKButtonText?: string;
}

export interface CatalogModalConfig extends ConfirmModalContent {
  tenantId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private dialogRef: MatDialogRef<any>;
  DEFAULT_HEIGHT = '210px';
  DEFAULT_WIDTH = '560px';

  constructor(private readonly dialog: MatDialog) {}

  public openConfirmDialog(modalContent: ConfirmModalContent): this {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();

    dialogConfig.maxHeight = this.DEFAULT_HEIGHT;
    dialogConfig.width = this.DEFAULT_WIDTH;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    // override defaults
    Object.assign(dialogConfig, modalContent.options);

    dialogConfig.data = {
      OKButtonText: modalContent.OKButtonText ?? 'OK',
      body: modalContent.body,
      cancelButtonText: modalContent.cancelButtonText ?? 'Cancel',
      cancelButtonVisible: true,
      header: modalContent.header,
    } as ConfirmModalContent;

    this.dialogRef = this.dialog.open(ModalComponent, dialogConfig);
    return this;
  }

  /**
   * @deprecated The method should not be used, implement wtui instead
   */
  public openAlertDialog(options: AlertModalContent): this {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();

    dialogConfig.maxHeight = '360px';
    dialogConfig.width = this.DEFAULT_WIDTH;
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      OKButtonText: options.OKButtonText || 'OK',
      body: options.body,
      cancelButtonVisible: false,
      header: options.header,
    } as AlertModalContent;
    this.dialogRef = this.dialog.open(ModalComponent, dialogConfig);
    return this;
  }

  public close(): void {
    this.dialog.closeAll();
  }

  public confirmed$(): Observable<any> {
    return this.dialogRef?.afterClosed();
  }

  public openWelcomeDialog(): this {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.height = '472px';
    dialogConfig.width = '748px';
    dialogConfig.autoFocus = false;
    this.dialogRef = this.dialog.open(WelcomeDialogComponent, dialogConfig);
    return this;
  }

  public openCatalogDialog(options: CatalogModalConfig): this {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.minHeight = '472px';
    dialogConfig.width = '748px';
    dialogConfig.data = {
      OKButtonText: options.OKButtonText,
      body: options.body,
      cancelButtonVisible: false,
      header: options.header,
      options: options.options,
      tenantId: options.tenantId,
    } as CatalogModalConfig;

    this.dialogRef = this.dialog.open(CatalogDialogComponent, dialogConfig);

    return this;
  }
}
