/**
 * TO DO: review for dead code
 */

import { HttpStatusCode } from '@angular/common/http';
import { MessageIcons, Role } from '@ra-state';

export interface MessageIconMap {
  [key: string]: { icon: MessageIcons; message: string };
}

export class CommonConstants {
  // Session Timeout Configuration
  public static readonly idealTimeout: number = 1500000; //25 Minutes
  public static readonly sessionTimeout: number = 180; // 3 Minute

  public static readonly responseMessageIconColorMap: MessageIconMap = {
    'add-access': { icon: MessageIcons.Success, message: 'Access has been added successfully.' },
    'duplicate-add-records': {
      icon: MessageIcons.Error,
      message: 'Some records are already associated with the selected user.',
    },
    'duplicate-edit-records': {
      icon: MessageIcons.Error,
      message: 'Some records are already associated with this user.',
    },
    'edit-access': { icon: MessageIcons.Success, message: 'Access has been modified successfully.' },
    'invite-user': { icon: MessageIcons.Error, message: 'Error - Email address you entered is not valid' },
    'license-agreement': { icon: MessageIcons.LicenseScroll, message: "Scroll to enable 'Accept' button" },
    'remove-access': { icon: MessageIcons.Info, message: 'Access has been removed successfully.' },
  };

  // Help Screen Data
  public static readonly CONTACT_SUPPORT_URL =
    'https://rockwellautomation.custhelp.com/app/chat/chat_landing/contacts.email/';
  static readonly entitlementNewPeriodInDays = 3;
  static readonly trialExpireSoonDays = 7;

  public static getEmailDomain(emailString: string): string {
    return emailString.split('@')[1];
  }

  public static readonly cleanLeftTrailingZeros = (str: string): string => {
    return str.replace(/^0+/, '');
  };

  public static readonly UserIDPConnections = {
    MyRockwell: 'MyRockwell',
  };
}

export const SELECTABLE_ROLES = [Role.Admin, Role.Contributor];

export type CustomErrorMessage = {
  errorCode: AppErrorCode | HttpStatusCode;
  message: string;
};

export type AppErrorCode =
  | 'EntitlementAlreadyConsumed'
  | 'EntitlementTransactionInProgress'
  | 'EntitlementError-InactiveEntitlement'
  | 'EntitlementError-platform entitlements cannot overlap'
  | 'CannotApplyEntitlementToPersonalTenant'
  | 'EntitlementNotActive'
  | 'InvitationAlreadyExists'
  | 'MinimumTimeBetweenInvitationReissueNotSatisfied'
  | 'UserAlreadyHasAccessToSpecifiedResource'
  | 'EntitlementError-Platform/Trial/NewTrial/Additive entitlement needs to applied for activating the service'
  | 'EntitlementError-Entitlement kind disallows quantity more than 1'
  | 'InvitationAlreadyAccepted'
  | 'InvitationAlreadyCancelled'
  | 'InvitationExpired'
  | 'invitation not in active state'
  | 'does not overlap platform entitlement';

export function getErrorMessage(
  errorCode: AppErrorCode | HttpStatusCode | string,
  customErrorMessages?: CustomErrorMessage[],
): string {
  if (customErrorMessages && customErrorMessages.length > 0) {
    const customErrorMessage = customErrorMessages.find((x) => x.errorCode === errorCode);
    if (customErrorMessage) {
      return customErrorMessage.message;
    }
  }
  switch (errorCode) {
    case 'EntitlementAlreadyConsumed':
      return 'Entitlement already consumed';
    case 'EntitlementTransactionInProgress':
      return 'Service provisioning is in progress, please complete the provisioning before applying the entitlement';
    case 'EntitlementError-InactiveEntitlement':
      return 'Cannot allocate, the entitlement is no more active';
    case 'EntitlementError-platform entitlements cannot overlap':
      return 'Service already has a platform entitlement allocated for the time period';
    case 'CannotApplyEntitlementToPersonalTenant':
      return 'Entitlements cannot be allocated to a personal organization';
    case 'EntitlementNotActive':
      return 'Entitlement is not active';
    case 'InvitationAlreadyExists':
      return 'Invitation for the user already exists';
    case 'MinimumTimeBetweenInvitationReissueNotSatisfied':
      return 'The invite was already sent.';
    case 'UserAlreadyHasAccessToSpecifiedResource':
      return 'Your account already has access to the organization';
    case 'EntitlementError-Platform/Trial/NewTrial/Additive entitlement needs to applied for activating the service':
      return 'Platform/Additive entitlement needs to applied before applying an Add-on entitlement';
    case 'EntitlementError-Entitlement kind disallows quantity more than 1':
      return 'Only one entitlement of type Platform can be allocated at a time';
    case 'InvitationAlreadyAccepted':
      return 'Invitation is already accepted for this organization.';
    case 'InvitationAlreadyCancelled':
      return 'Invitation is cancelled. Please contact your administrator.';
    case 'InvitationExpired':
      return 'Invitation is expired. Please contact your administrator.';
    case 'invitation not in active state':
      return 'Invitation is not active. Please contact your administrator.';
    case 403:
      return 'Insufficient Permissions: Redirecting to dashboard';
    default:
      // TODO: Hack for dynamic error code from server
      if (typeof errorCode === 'string') {
        const regex = /does not overlap platform entitlement/;
        if (regex.exec(errorCode)) {
          return 'Add-On entitlement dates does not overlap with platform entitlement dates';
        }
      }
      return '';
  }
}
